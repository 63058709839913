import React from "react";
import { AppContext } from "../../../context";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Datatable from "../../Datatable";
import Request from "../../../request";

class RelatorioGfluList extends React.Component {
  static contextType = AppContext;

  onAction(action, callback) {
    switch (action.name) {
    case "print":
        Request('GET', this.context.config.BACKEND_URL + '/relatorio/print/' + action.id, this.context.token)
        .responseType('blob')
        .then(res => {
            const url = window.URL.createObjectURL(res.body);
            var element = document.createElement('a');
            element.setAttribute('href', url);
            element.setAttribute('target', "_blank");

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        })
        .catch(err => {
            this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do arquivo: " + err.toString() });
        });
    break;
      case "edit":
        Request(
          "GET",
          this.context.config.BACKEND_URL +
            "/grupamentoFluvial/relatorio/" +
            action.id,
          this.context.token
        )
          .then((res) => {
            this.context.setContent("RelatorioGfluForm", {
              relatorio: res.body,
            });
          })
          .catch((err) => {
            this.context.addToast({
              titulo: "Erro",
              conteudo:
                "Houve uma falha na recuperação do registro: " + err.toString(),
            });
          });
        break;
      case "delete":
        if (window.confirm("Deseja realmente excluir este registro?")) {
          Request(
            "DELETE",
            this.context.config.BACKEND_URL +
              "/grupamentoFluvial/relatorio/" +
              action.id,
            this.context.token
          )
            .then((res) => {
              callback();
            })
            .catch((err) => {
              this.context.addToast({
                titulo: "Erro",
                conteudo:
                  "Houve uma falha na exclusão do registro: " + err.toString(),
              });
            });
        }
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <>
        <h3>
          <FontAwesomeIcon icon={faBook} /> Lista de Relatórios
        </h3>
        <h6>
          <strong>
            <a
              className="btn btn-link-dark"
              target="_blank"
              rel="noreferrer"
              href={`${this.context.config.FRONTEND_URL}/doc/PORTARIA_PISP_N148_2024_SEGUP.pdf`}
              download="PORTARIA_PISP_N148_2024_SEGUP.pdf"
            >
              (Portaria de Envio do Relatório Mensal de Produtividade)
            </a>
          </strong>
        </h6>
        <hr />
        <Datatable
          url={this.context.config.BACKEND_URL + "/grupamentoFluvial/relatorio"}
          autoSeach={true}
          useAdd={this.context.usuario.Permissoes.some(
            (p) => p.nome === "grupamentoFluvial_admin"
          )}
          onError={(err) =>
            this.context.addToast({ titulo: "Erro", conteudo: err.toString() })
          }
          onAction={(action, update) => this.onAction(action, update)}
          onClickAdd={() =>
            this.context.setContent("RelatorioGfluForm", { relatorio: {} })
          }
        />
      </>
    );
  }
}

export default RelatorioGfluList;
