import React from 'react';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';
import UsuarioSetorForm from './UsuarioSetorForm';

class UsuarioSetorList extends React.Component {    

    static contextType = AppContext;

    onAction(action, defaultHandler){ 
        switch (action.name){
            case 'edit':
                this.context.setContent("UsuarioSetorForm", {title: "Criar Observação"});
                break;
            default:
                defaultHandler();
                break;                
        }
    }

    render(){
        return (
            <> 
            <ModelList 
                url={this.context.config.BACKEND_URL + '/disquedenuncia/observacao/usuario'}
                title="Lista de Usuários no Setor"
                modelName="usuario"
                modelIcon="faThList"
                modalSize = "lg"
                modelForm={UsuarioSetorForm}
                useAdd={false}
            />
               {console.log(this.props)}
            </>
           
        );
    }
}


export default UsuarioSetorList;
